import {Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { LoraNode } from "../../../model/lora-node/LoraNode";
import { useEffect, useState } from "react";
import { isPointInPolygon } from "geolib";
import { GenericNestedList } from "../../MaterialOnFire/GenericUIViews/GenericNestedList/GenericNestedList";
import { useNavigate } from "react-router-dom";
import { getLoraNodeActionsFromHardWareType } from "../../custom-hooks/uiEnhancers/MaintainLoraNodeActions";

export const NodeOverview = () => {
  const mapState = useSelector((state: RootState) => state.map);

  const [visibleNodes, setVisibleNodes] = useState<LoraNode[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (mapState?.visibleNodes && mapState?.viewPort) {
      let viewPort = mapState.viewPort;
      let newVisibleNodes: LoraNode[] = mapState.visibleNodes.filter(
        (node: LoraNode) => {
          return isPointInPolygon(
            { lat: node.location.lat, lng: node.location.lng },
            [
              viewPort.topLeft,
              viewPort.topRight,
              viewPort.bottomRight,
              viewPort.bottomLeft,
            ]
          );
        }
      );
      setVisibleNodes(newVisibleNodes);
    }
  }, [mapState.visibleNodes, mapState.viewPort, mapState]);

  return (
    <GenericNestedList
      list={visibleNodes}
      isSelected={(item: LoraNode) => item.id === mapState.selectedElement?.id}
      listItemOnClick={(item: LoraNode) => () => {
        navigate("/loraNodes/" + item.id);
      }}
      getListItemVisualizer={(item: LoraNode) => {
        return <Typography>{item.ttnPayload.ids.device_id}</Typography>;
      }}
      getListItemActions={(item: LoraNode) => {
        if (item.hardwareType) {
          return getLoraNodeActionsFromHardWareType(item.hardwareType);
        }
        return [];
      }}
      getNestingCriteriaFromListItem={(item: LoraNode) => {
        return item?.group?.id || "NO_GROUP";
      }}
      getNestingCriteriaVisualizer={(item: LoraNode) => {
        return <Typography> {item.group?.name || "NO_GROUP"} </Typography>;
      }}
      getNestingCriteriaActions={() => []}
    />
  );
};
