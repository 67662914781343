import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useEffect } from "react";
import { fetchAllCachedGroups } from "../../../model/groups/groupState";
import {
  GenericValueHelpResult,
  registerValueHelp,
} from "../GenericUIFields/GenericSelect/GenericValueHelpState";
import { FieldTypes, UiDescriptor } from "../GenericUIFields/MaintenanceView";

export const useRegisterUIDefinitionValueHelp = () => {
  const uiDefinitions = useSelector(
    (state: RootState) => state.uiDefinitions.uiDefinitions
  );
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const referencedUIDefinitionValueHelp: GenericValueHelpResult[] =
      uiDefinitions.map((value) => {
        return {
          id: value.id,
          displayValue: value.name,
          object: value,
        };
      });
    dispatch(
      registerValueHelp({
        name: "referencedUIDefinition",
        valueHelpResult: referencedUIDefinitionValueHelp,
      })
    );

    let fieldTypes = Object.keys(FieldTypes);

    const fieldTypeVauleHelp: GenericValueHelpResult[] = fieldTypes.map(
      (key) => {
        return {
          id: key,
          displayValue: key,
          object: key,
        };
      }
    );

    dispatch(
      registerValueHelp({
        name: "fieldType",
        valueHelpResult: fieldTypeVauleHelp,
      })
    );

    const fieldSizeValueHelp: GenericValueHelpResult[] = [];
    for (let i = 1; i <= 12; i++) {
      fieldSizeValueHelp.push({
        id: i + "",
        displayValue: i + "",
        object: i,
      });
    }

    dispatch(
      registerValueHelp({
        name: "size",
        valueHelpResult: fieldSizeValueHelp,
      })
    );
  }, [uiDefinitions]);
};
