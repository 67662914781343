import { GenericMaintenanceList } from "../GenericUIViews/GenericMaintenanceList/GenericMaintenanceList";
import { Typography } from "@mui/material";
import { UIBuilderUIDescriptor } from "./UIBuilderUIDescriptor";

import {
  getNewUiDefinition,
  uiFirebaseServices,
} from "./uiBuilderServices/uiFirebaseServices";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  addUIDefinition,
  deleteUIDefinition,
  updateUIDefinition,
} from "./uiBuilderServices/uiDefinitionState";
import { useRegisterUIDefinitionValueHelp } from "../custom-hooks/useRegisterUIDefinitionValueHelp";
import { useNavigate } from "react-router-dom";
import { useGetSaveAndDeleteActions } from "../custom-hooks/useGetSaveAndDeleteActions";
import { UiDefinition } from "./uiBuilderServices/uiDefinition";
import { useRegisterReferenceValueHelp } from "../custom-hooks/useRegisterReferenceValueHelp";
import { useTranslation } from "../custom-hooks/useTranslation";
export const UIBuilder = () => {
  const dispatch: AppDispatch = useDispatch();

  const uiDefinitions = useSelector((state: RootState) => {
    return state.uiDefinitions.uiDefinitions;
  });
  const { updateTranslationKeysForModel } = useTranslation();
  const navigate = useNavigate();

  useRegisterUIDefinitionValueHelp();

  const actions = useGetSaveAndDeleteActions<UiDefinition>({
    uiDefinition: {
      uiDescriptorDefinition: UIBuilderUIDescriptor,
    } as UiDefinition,
    saveAction: (newState) => {
      dispatch(updateUIDefinition(newState));
      uiFirebaseServices.upsertItem(newState);
      updateTranslationKeysForModel(newState);
    },
    deleteAction: (newState) => {
      dispatch(deleteUIDefinition(newState));
      uiFirebaseServices.deleteItem(newState);
    },
  });

  useRegisterReferenceValueHelp();

  return (
    <>
      <GenericMaintenanceList
        translationPrefix={"UIBuilder"}
        list={uiDefinitions}
        listItemDisplayFunction={(state) => {
          return <Typography> {state.name} </Typography>;
        }}
        uiDescriptorTable={UIBuilderUIDescriptor}
        addNewAction={() => {
          const newUiDefinition = getNewUiDefinition();
          dispatch(addUIDefinition(newUiDefinition));
          navigate("../uiBuilderServices-builder/" + newUiDefinition.id);
        }}
        maintenanceViewActions={actions}
        routingOptions={{
          baseRoute: "/uiBuilderServices-builder",
          idFieldName: "id",
        }}
      />
    </>
  );
};
