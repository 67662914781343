import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useGetRoutes } from "../custom-hooks/useGetRoutes";
import { useTranslation } from "../custom-hooks/useTranslation";

export default () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { getRoutes } = useGetRoutes();
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      //gap={4}
      spacing={4}
      sx={{ width: "100%", height: "flex-grow" }}
    >
      {getRoutes().map((route) => {
        return (
          <Grid item xs={12} sm={5} md={4} xl={2} sx={{ height: "flexWrap" }}>
            {route.landingPageTile}
          </Grid>
        );
      })}
    </Grid>
  );
};
