import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ViewPort } from "../../../map/mapState";
import { GenericFirebaseFields } from "../../UI-Builder/uiBuilderServices/BaseFireBaseServices";

interface GenericMaintenanceListState<T extends GenericFirebaseFields> {
  selected: T | null;
  isDirty: boolean;
  hasError: boolean;
  currentList: T[];
}

const initialState: GenericMaintenanceListState<any> = {
  selected: null,
  isDirty: false,
  hasError: false,
  currentList: [],
};
const maintenanceListSlice = createSlice({
  name: "MaintenanceList",
  initialState,
  reducers: {
    setSelectedListItem: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },

    setCurrentList: (state, action: PayloadAction<any[]>) => {
      state.currentList = action.payload;
    },

    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },

    setHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },

    setInitUi: (state) => {
      state.hasError = false;
      state.isDirty = false;
    },
  },
});

export const {
  setSelectedListItem,
  setCurrentList,
  setIsDirty,
  setHasError,
  setInitUi,
} = maintenanceListSlice.actions;
export const maintenanceListReducer = maintenanceListSlice.reducer;
