import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "../custom-hooks/useTranslation";
import { ReactNode } from "react";
import { Add, QuestionMark } from "@mui/icons-material";
import { useGetIconList } from "../custom-hooks/useGetIconList";

export interface LandingPageTileProps {
  link: string;
  name: string;
  display?: string;
  Icon?: string;
}

export default ({ link, name, display, Icon }: LandingPageTileProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const iconList = useGetIconList();


  return (
    <Card
      onClick={() => navigate(link)}
      sx={{
        height: "flexWrap",
        width: "100%",
        cursor: "pointer",
      }}
    >
      <CardContent sx={{ width: "100%", height: "100%" }}>
        <Stack sx={{ width: "100%", height: "100%" }}>
          <Typography variant={"h6"} sx={{ flexGrow: 1 }} align={"center"}>
            {t(name)}
          </Typography>

          <Typography>{display || ""}</Typography>

          <ListItemIcon sx={{ height: "flexWrap", width: "100%" }}>
            <Typography sx={{ flexGrow: 1 }} />
            {iconList.getIcon(Icon || "", { fontSize: "60px" })}
          </ListItemIcon>
        </Stack>
      </CardContent>
    </Card>
  );
};
