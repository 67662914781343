import { useDispatch, useSelector } from "react-redux";
import { addReducer, AppDispatch, RootState } from "../../../store";
import { UiDefinition } from "../UI-Builder/uiBuilderServices/uiDefinition";
import { useEffect, useState } from "react";
import {
  GenericValueHelpResult,
  registerValueHelp,
} from "../GenericUIFields/GenericSelect/GenericValueHelpState";
import { getValue } from "@testing-library/user-event/dist/utils";

export const useResolveReferenceObjectToSelectValueHelp = (
  fieldname: string,
  uiDefinition?: UiDefinition
) => {
  const items: any[] = useSelector(
    //@ts-ignore
    (state: any) => state[uiDefinition?.model]?.items
  );

  const dispatch: AppDispatch = useDispatch();
  //@ts-ignore
  const reducerSet = addReducer(uiDefinition);
  const [valueHelp, setValueHelp] = useState<GenericValueHelpResult[]>([]);

  useEffect(() => {
    if (reducerSet) dispatch(reducerSet.fetchAll());
  }, [uiDefinition?.model]);

  useEffect(() => {
    if (uiDefinition) {
      const lclValueHelp: GenericValueHelpResult[] = items.map((value) => {
        return {
          id: value.id,
          displayValue: value.name,
          object: {
            ...value,
            _needsFirebaseRef: true,
            _fireBasePath: uiDefinition.model + "/",
          },
        };
      });
      dispatch(
        registerValueHelp({
          name: fieldname,
          valueHelpResult: lclValueHelp,
        })
      );
      setValueHelp(lclValueHelp);
    }
  }, [items]);

  return () => {
    return valueHelp;
  };
};
