import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAllTranslations, Translations } from "./translationsState";

import { LoraNode } from "../../../model/lora-node/LoraNode";
import { getLoraNodeActionsFromHardWareType } from "../../custom-hooks/uiEnhancers/MaintainLoraNodeActions";
import { GenericNestedList } from "../GenericUIViews/GenericNestedList/GenericNestedList";
import { GenericActionBar } from "../GenericUIFields/GenericActionBar";
import { upsertTranslationToFirebase } from "./translationFirebase";
import {
  ArrowDropDown,
  ArrowRight,
  Key,
  Save,
  Translate,
} from "@mui/icons-material";
//@ts-ignore
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { useTranslation } from "../custom-hooks/useTranslation";
import { TreeItem, TreeView } from "@mui/lab";
import { useSnackbar } from "notistack";

export const RenderTree = (
  object: any,
  path: string,
  updateKey: (key: string, value: string) => void,
  disabled?: boolean
) => {
  const keys = Object.keys(object);
  return keys.map((key) => {
    if (typeof object[key] === "string") {
      return (
        <TreeItem
          nodeId={`${path}.${key}`}
          label={
            <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
              <Box component={Paper} color="primary" sx={{ mr: 1 }} />
              <Typography
                variant="caption"
                color="primary"
                sx={{ flexGrow: 1 }}
              >
                <TextField
                  fullWidth
                  label={
                    <Typography>
                      <Key /> {key}
                    </Typography>
                  }
                  disabled={disabled}
                  onChange={(e) =>
                    updateKey(`${path}.${key}`, e.currentTarget.value)
                  }
                  key={`${path}.${key}`}
                  variant={"standard"}
                  value={object[key]}
                />
              </Typography>
            </Box>
          }
        ></TreeItem>
      );
    }

    return (
      <TreeItem nodeId={`${path}.${key}`} label={key}>
        {RenderTree(object[key], `${path}.${key}`, updateKey, disabled)}
      </TreeItem>
    );
  });
};

export const Translator = () => {
  const translations = useSelector((state: RootState) => state.translations);
  const dispatch: AppDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  //Dieser State wird benutzt, um die Änderungen mit zu Tracken
  //der initiale Render erfolgt aus dem Redux State
  //die TranslationKey component hält ihren eigenen State und schreibt die Änderungen
  //in diesen State fort. Das sorgt bei der tiefen Liste dafür, dass
  //nicht jedes mal der gesamte Baum neu geändert wird.
  const [changedTranslation, setChangedTranslation] = useState(
    translations.translations[0]
  );

  useEffect(() => {
    setChangedTranslation(translations.translations[0]);
  }, [translations]);

  useEffect(() => {
    dispatch(fetchAllTranslations());
  }, []);

  const updateKey = (key: string, value: string) => {
    let unfrozenCopy = JSON.parse(
      JSON.stringify(changedTranslation.translations)
    );
    let newTranslation = set(unfrozenCopy, key.replace("root.", ""), value);

    setChangedTranslation({
      ...changedTranslation,
      translations: newTranslation,
    });
  };

  if (!changedTranslation) return <></>;
  return (
    <Paper>
      <GenericActionBar
        state={translations}
        actions={[
          {
            action: () => {
              upsertTranslationToFirebase(changedTranslation);
              dispatch(fetchAllTranslations());
              enqueueSnackbar(t("saveSuccess"), { variant: "success" });
            },
            name: "Save",
            icon: <Save />,
          },
        ]}
      />
      <Grid container>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Typography>{changedTranslation.language}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TreeView
              sx={{
                height: "100%",
                flexGrow: 1,
                width: "100%",
                overflowY: "auto",
              }}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
            >
              {RenderTree(changedTranslation.translations, "root", updateKey)}
            </TreeView>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const set = (obj: any, path: any, value: any) => {
  if (Object(obj) !== obj) return obj; // When obj is not an object
  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
  path.slice(0, -1).reduce(
    (
      a: any,
      c: any,
      i: any // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] =
            Math.abs(path[i + 1]) >> 0 === +path[i + 1]
              ? [] // Yes: assign a new array object
              : {}), // No: assign a new plain object
    obj
  )[path[path.length - 1]] = value; // Finally assign the value to the last key
  return obj; // Return the top-level object to allow chaining
};

/*
<Editor
            value={changedTranslation.translations}
            onChange={(data: unknown) => {
              setChangedTranslation({
                ...changedTranslation,
                translations: data as any,
              });
              console.log(data);
            }}
          />
 */
