import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  MaintenanceView,
  UiDescriptor,
} from "../../GenericUIFields/MaintenanceView";
import { ReactNode, useEffect, useId, useState } from "react";
import { GenericActionBarAction } from "../../GenericUIFields/GenericActionBar";
import { useNavigate, useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  setCurrentList,
  setIsDirty,
  setSelectedListItem,
} from "./GenericMaintenanceListState";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GenericConfirmDialog } from "../GenericConfirmDialog";
import { WarningAmber } from "@mui/icons-material";
import { useTranslation } from "../../custom-hooks/useTranslation";
export const GenericMaintenanceList = (props: {
  list: any[];
  listItemDisplayFunction: (item: any) => ReactNode;
  uiDescriptorTable: UiDescriptor[];
  translationPrefix: string;
  maintenanceViewActions: GenericActionBarAction[];
  routingOptions: {
    idFieldName: string;
    baseRoute: string;
  };
  addNewAction?: () => void;
}) => {
  const [localList, setLocalList] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(null);
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [navigationTarget, setNavigationTarget] = useState(false);
  const [confirmAction, setConfirmAction] = useState<"addNew" | "navigate">();
  const isDirty = useSelector(
    (state: RootState) => state.maintenanceList.isDirty
  );

  const getIdFieldValue = (value: any, idFieldName: string) => {
    let fields = idFieldName.split(".");
    let targetValue = value;

    for (let field of fields) {
      targetValue = targetValue[field];
    }

    return targetValue;
  };

  useEffect(() => {
    let index = localList.findIndex((value) => {
      let targetValue = getIdFieldValue(
        value,
        props.routingOptions.idFieldName
      );
      if (targetValue) {
        return targetValue === id;
      }
    });
    let item = index >= 0 ? localList[index] : null;
    setSelected(item);
    dispatch(setSelectedListItem(item));
  }, [id, localList]);

  useEffect(() => {
    console.count("GenericMaintenanceList Props List Changes");
    setLocalList(props.list);
    dispatch(setCurrentList(props.list));
  }, [props.list]);

  return (
    <Grid container sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
      <Grid
        container
        alignContent={"flex-start"}
        component={Paper}
        sx={{ mr: "1%", width: "25%", p: 1, height: "100%", overflow: "auto" }}
      >
        {props?.addNewAction ? (
          <Grid item xs={12}>
            <IconButton
              onClick={() => {
                setConfirmAction("addNew");
                setConfirmDialogOpen(true);
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <List>
            {localList ? (
              localList.map((item: any, index: number) => {
                const isSelected = selected
                  ? getIdFieldValue(item, props.routingOptions.idFieldName) ===
                      getIdFieldValue(
                        selected,
                        props.routingOptions.idFieldName
                      ) &&
                    getIdFieldValue(selected, props.routingOptions.idFieldName)
                  : false;
                return (
                  <>
                    <ListItemButton
                      key={index}
                      selected={isSelected}
                      onClick={() => {
                        setConfirmAction("navigate");
                        setConfirmDialogOpen(true);
                        setNavigationTarget(item);
                      }}
                    >
                      {props.listItemDisplayFunction(item)}
                      {isSelected && isDirty ? (
                        <Tooltip title={t("componentIsDirty")}>
                          <WarningAmber color={"warning"} />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </ListItemButton>
                    <Divider />
                  </>
                );
              })
            ) : (
              <></>
            )}
          </List>
        </Grid>
      </Grid>
      <Grid
        container
        component={Paper}
        alignContent={"flex-start"}
        sx={{ width: "74%", p: 1, height: "100%" }}
        overflow={"auto"}
      >
        {selected ? (
          <MaintenanceView
            data={selected}
            translationPrefix={props.translationPrefix}
            uiDescriptorTable={props.uiDescriptorTable}
            actions={props.maintenanceViewActions}
            hideFieldsWithoutUiDescriptor={true}
            asSection={true}
          />
        ) : (
          <></>
        )}
      </Grid>
      <GenericConfirmDialog
        open={confirmDialogOpen}
        text={t("confirmDialog.confirmIsDirtyWarning")}
        canAutoConfirm={!isDirty}
        confirmAction={() => {
          if (confirmAction === "navigate") {
            navigate(
              "../" +
                props.routingOptions.baseRoute +
                "/" +
                getIdFieldValue(
                  navigationTarget,
                  props.routingOptions.idFieldName
                ),
              {
                replace: true,
              }
            );
          }

          if (confirmAction === "addNew") {
            if (props.addNewAction) props.addNewAction();
          }

          setConfirmDialogOpen(false);
          dispatch(setIsDirty(false));
        }}
        cancelAction={() => setConfirmDialogOpen(false)}
      />
    </Grid>
  );
};
