import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum themeMode {
  light = "light",
  dark = "dark",
}
export interface AppBarState {
  searchString: string;
  searchEnabled: boolean;
  drawerOpen: boolean;
  themeMode: themeMode | string;
}

const initialState: AppBarState = {
  searchString: "",
  searchEnabled: false,
  drawerOpen: false,
  themeMode: themeMode.light,
};

export const appBarSlice = createSlice({
  name: "appBar",
  initialState,
  reducers: {
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setSearchEnabled: (state, action: PayloadAction<boolean>) => {
      state.searchEnabled = action.payload;
    },
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<themeMode>) => {
      state.themeMode = action.payload;
      localStorage.setItem("themeMode", action.payload.toString());
    },
  },
});

export const {
  setSearchString,
  setSearchEnabled,
  setDrawerOpen,
  setThemeMode,
} = appBarSlice.actions;
export const appBarReducer = appBarSlice.reducer;
