import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { fireBaseApp } from "../firebase-config";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { QuestionMark, Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "../custom-hooks/useTranslation";

import { useSnackbar } from "notistack";

export default () => {
  //TODO Fix login
  const { t } = useTranslation();

  const [loginName, setLoginName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const auth = getAuth(fireBaseApp);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Grid
      container
      justifyContent={"center"}
      alignContent={"center"}
      sx={{ height: "100%", width: "100%", backgroundColor: "paper.dark" }}
    >
      <Grid
        item
        container
        justifyContent={"center"}
        spacing={2}
        alignItems={"center"}
        sx={{ width: "40%", p: 4, backgroundColor: "paper.light" }}
        component={Paper}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <img src={"/logo.png"} style={{ width: "27vw" }} />
        </Grid>
        <Grid item container xs={12} justifyContent={"center"}>
          <TextField
            label={t("login.loginName")}
            fullWidth
            onChange={(event) => setLoginName(event.currentTarget.value)}
            name={"login"}
            value={loginName}
          />
        </Grid>
        <Grid item container xs={12} justifyContent={"center"}>
          <FormControl
            variant="outlined"
            sx={{
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("login.password")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(event) => setPassword(event.currentTarget.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event: React.MouseEvent) => {
                      event.preventDefault();
                    }}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        <Grid item container xs={6} justifyContent={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) =>
              signInWithEmailAndPassword(auth, loginName, password)
                .then()
                .catch((e) => {
                  enqueueSnackbar(e.message, { variant: "error" });
                })
            }
          >
            <Typography>{t("login.login")}</Typography>
          </Button>
        </Grid>

        <Grid item xs={6} justifyContent={"center"}>
          <Button
            variant={"outlined"}
            onClick={() => {
              const auth = getAuth(fireBaseApp);
              sendPasswordResetEmail(auth, loginName)
                .then(() => {
                  enqueueSnackbar(`Email an ${loginName} gesendet`, {
                    variant: "info",
                  });
                })
                .catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
            }}
          >
            {t("login.forgetPassword")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
