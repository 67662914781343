import {
  deleteHardwareType,
  updateHardwareType,
} from "../../../model/hardware-type/hardwareTypeState";
import hardwareTypeFireBaseServices from "../../../model/hardware-type/hardwareTypeFireBaseServices";
import { Delete, Save } from "@mui/icons-material";
import { AppDispatch, RootState } from "../../../store";
import { GenericActionBarAction } from "../GenericUIFields/GenericActionBar";
import { useDispatch, useSelector } from "react-redux";
import { setIsDirty } from "../GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import { useSnackbar } from "notistack";
import { UiDescriptor } from "../GenericUIFields/MaintenanceView";
import { UiDefinition } from "../UI-Builder/uiBuilderServices/uiDefinition";
import { useEffect, useState } from "react";
import { useTranslation } from "./useTranslation";

interface SaveAndDeleteActions<T> {
  saveAction?: (newState: T) => void;
  deleteAction?: (newState: T) => void;
  uiDefinition?: UiDefinition;
}

const checkRequiredFieldsFilled = (value: any, uiDescriptor: UiDescriptor) => {
  if (!uiDescriptor) return [];
  if (uiDescriptor.required && !value) {
    return [uiDescriptor.fieldName];
  }
  if (uiDescriptor?.childUIDescriptor?.length) {
    let fields: string[] = [];
    if (uiDescriptor.fieldType !== "table") {
      uiDescriptor.childUIDescriptor.forEach((descriptor) => {
        fields = [
          ...fields,
          ...checkRequiredFieldsFilled(value[descriptor.fieldName], descriptor),
        ];
      });
    }
    return fields;
  }
  return [] as string[];
};

const checkUiHasMissingFields = (state: any, uiDefinition: UiDefinition) => {
  let unfilledFields: string[] = [];
  if (uiDefinition) {
    for (let uiDescriptor of uiDefinition.uiDescriptorDefinition) {
      unfilledFields = [
        ...unfilledFields,
        ...checkRequiredFieldsFilled(
          state[uiDescriptor.fieldName],
          uiDescriptor
        ),
      ];
    }
  }

  return {
    hasMissingFields: unfilledFields.length > 0,
    missingFields: unfilledFields,
  };
};

export function useGetSaveAndDeleteActions<T>(props: SaveAndDeleteActions<T>) {
  const actions: GenericActionBarAction[] = [];
  const dispatch: AppDispatch = useDispatch();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const uiHasErrors = useSelector(
    (state: RootState) => state.maintenanceList.hasError
  );

  if (props?.saveAction) {
    actions.push({
      action: (newState: T) => {
        if (props.saveAction) {
          if (props.uiDefinition) {
            const requiredFields = checkUiHasMissingFields(
              newState,
              props.uiDefinition
            );
            if (requiredFields.hasMissingFields) {
              snackbar.enqueueSnackbar(t("SaveError.RequiredFieldsMissing"), {
                variant: "error",
              });
              return;
            }
          }

          if (uiHasErrors) {
            snackbar.enqueueSnackbar(t("SaveError.UIHasErrors"), {
              variant: "error",
            });
            return;
          }

          props.saveAction(newState);
          dispatch(setIsDirty(false));
        }
      },
      name: "Save",
      icon: <Save />,
    });
  }

  if (props?.deleteAction) {
    actions.push({
      action: (newState: T) => {
        if (props.deleteAction) {
          props.deleteAction(newState);
          dispatch(setIsDirty(false));
        }
      },
      name: "Delete",
      icon: <Delete />,
    });
  }

  return actions;
}
