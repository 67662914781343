import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "../custom-hooks/useTranslation";

export interface GenericConfirmDialogInterface {
  open: boolean;
  text: string;
  canAutoConfirm?: boolean;
  confirmAction: () => void;
  cancelAction: () => void;
}

export const GenericConfirmDialog = (props: GenericConfirmDialogInterface) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.open && props?.canAutoConfirm) {
      props.confirmAction();
    }
  }, [props.open, props?.canAutoConfirm]);

  return (
    <Dialog
      open={props.open && !props.canAutoConfirm}
      onClose={props.cancelAction}
    >
      <DialogContent>
        <Typography aria-multiline={true}>{props.text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={props.confirmAction}
        >
          {t("GenericConfirmDialog.ConfirmAction")}
        </Button>
        <Button
          variant={"outlined"}
          color={"secondary"}
          onClick={props.cancelAction}
        >
          {t("GenericConfirmDialog.CancelAction")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
