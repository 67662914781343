import { Save } from "@mui/icons-material";
import MessageIcon from "@mui/icons-material/Message";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  HardwareInstruction,
  HardwareType,
} from "../../../model/hardware-type/hardwareType";
import { LoraNode } from "../../../model/lora-node/LoraNode";
import {
  getDownlinkFunction,
  loraNodeCRUDServices,
} from "../../../model/lora-node/loraNodeFirebaseServices";
import { GenericActionBarAction } from "../../MaterialOnFire/GenericUIFields/GenericActionBar";
import { AppDispatch, RootState } from "../../../store";
import { updateLoraNode } from "../../../model/lora-node/loraNodeState";
import { useGetIconList } from "../../MaterialOnFire/custom-hooks/useGetIconList";
import { useSelector } from "react-redux";

function mapHardwareInstructionToActionBarAction(
  instruction: HardwareInstruction,
  getIcon: (iconName: string, sx?: any) => JSX.Element,
  enqueueSnackbar?: (text: string, options: any) => void
) {
  return {
    action: (state: LoraNode) => {
      console.info(
        "applicationId",
        state.ttnPayload.ids.application_ids.application_id
      );
      const downlink = getDownlinkFunction()({
        applicationId: state.ttnPayload.ids.application_ids.application_id,
        device: state.ttnPayload.ids.device_id,
        payload: instruction.bytes,
      });

      if (enqueueSnackbar) {
        downlink
          .then(() =>
            enqueueSnackbar("Befehl gesendet", { variant: "success" })
          )
          .catch((e) => enqueueSnackbar(e.message, { variant: "error" }));
      }
    },
    name: instruction.name,
    icon: getIcon(instruction.icon),
  } as GenericActionBarAction;
}

export const getLoraNodeActionsFromHardWareType = (
  hardwareType: HardwareType,
  cachedHardwaretypes?: HardwareType[],
  enqueueSnackbar?: (text: string, options: any) => void
) => {
  if (cachedHardwaretypes) {
    let index = cachedHardwaretypes.findIndex(
      (value) => value.id === hardwareType.id
    );

    if (index > 0) {
      const instructions = cachedHardwaretypes[index].instructions;
      if (instructions) {
        return instructions.map((instruction) =>
          mapHardwareInstructionToActionBarAction(
            instruction,
            useGetIconList().getIcon,
            enqueueSnackbar
          )
        );
      }
    } else {
      const instructions = hardwareType.instructions;
      if (instructions) {
        return instructions.map((instruction) =>
          mapHardwareInstructionToActionBarAction(
            instruction,
            useGetIconList().getIcon,
            enqueueSnackbar
          )
        );
      }
    }
  }

  const instructions = hardwareType.instructions;
  if (instructions) {
    return instructions.map((instruction) =>
      mapHardwareInstructionToActionBarAction(
        instruction,
        useGetIconList().getIcon
      )
    );
  }

  //In case of no instructions return empty array
  return [];
};

export const getMaintainLoraNodeActions = (
  dispatch: AppDispatch,
  setSelected: (node: LoraNode) => void,
  setOpen: (open: boolean) => void,
  hardwareType?: HardwareType,
  cachedHardwaretypes?: HardwareType[]
) => {
  let hardwareInstructions: GenericActionBarAction[] = [];

  if (hardwareType) {
    hardwareInstructions = getLoraNodeActionsFromHardWareType(
      hardwareType,
      cachedHardwaretypes
    );
  }

  return [
    {
      action: (state: LoraNode) => {
        dispatch(updateLoraNode(state));
        loraNodeCRUDServices.upsertItem(state);
      },
      name: "Save",
      icon: <Save />,
    },
    {
      action: (state: LoraNode) => {
        dispatch(updateLoraNode(state));
        loraNodeCRUDServices.deleteItem(state);
      },
      name: "Delete",
      icon: <DeleteIcon />,
    },
    {
      action: (state: LoraNode) => {
        setSelected(state);
        setOpen(true);
      },
      name: "Show Messages",
      icon: <MessageIcon />,
    },
    ...hardwareInstructions,
  ] as GenericActionBarAction[];
};
