import { initializeApp } from "firebase/app";
import {
  CACHE_SIZE_UNLIMITED,
  enableIndexedDbPersistence,
  initializeFirestore,
} from "firebase/firestore";

export interface fireBaseAppInterface {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}
export let fireBaseApp: any = undefined;
let db: any = undefined;
export let firebaseDB = db;
export const initFireBaseApp = (firebaseConfig: fireBaseAppInterface) => {
  if (fireBaseApp) {
  } else {
    fireBaseApp = initializeApp(firebaseConfig);
    db = initializeFirestore(fireBaseApp, {
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    });
    firebaseDB = db;
    enableIndexedDbPersistence(db).catch((err) => {
      if (err.code == "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
  }
};
