import { UiDescriptor } from "../MaintenanceView";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { GenericTablePopup } from "./GenericTablePopup";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { GenericTokenizer } from "../GenericTokenizer/GenericTokenizer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useGetIconList } from "../../custom-hooks/useGetIconList";
import { useTranslation } from "../../custom-hooks/useTranslation";

const HeaderFromUiDescriptor = (props: {
  uiDescriptorTable: UiDescriptor[];
  translationPrefix: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {props.uiDescriptorTable.map((uiDescriptor) => {
        return (
          <>
            {uiDescriptor?.childUIDescriptor && uiDescriptor.visible ? (
              <HeaderFromUiDescriptor
                translationPrefix={props.translationPrefix}
                uiDescriptorTable={uiDescriptor.childUIDescriptor}
              />
            ) : uiDescriptor.visible ? (
              <TableCell>
                {t(`${props.translationPrefix}.${uiDescriptor.fieldName}`)}
              </TableCell>
            ) : (
              ""
            )}
          </>
        );
      })}
    </>
  );
};

const RowCellsFromData = (props: {
  data: any;
  translationPrefix: string;
  uiDescriptorTable: UiDescriptor[];
}) => {
  const iconList = useGetIconList();
  return (
    <>
      {props.uiDescriptorTable.map((uiDescriptor) => {
        let renderValue: ReactNode = <></>;

        switch (uiDescriptor.fieldType) {
          case "tokenizer":
            renderValue = (
              <GenericTokenizer
                translationPrefix={props.translationPrefix}
                disabled={true}
                onChange={() => {}}
                fieldKey={uiDescriptor.fieldName}
                value={props.data[uiDescriptor.fieldName]}
                uiDescriptor={uiDescriptor}
              />
            );
            break;
          case "checkbox":
            renderValue = (
              <Checkbox checked={props.data[uiDescriptor.fieldName] || false} />
            );
            break;
          case "table":
            renderValue = "";
            break;
          case "icon":
            renderValue = iconList.getIcon(props.data[uiDescriptor.fieldName]);
            break;
          case "selectField":
            renderValue = Object.keys(
              props.data[uiDescriptor.fieldName]?.length > 1
            )
              ? ""
              : props.data[uiDescriptor.fieldName];
            break;
          default:
            renderValue = props.data[uiDescriptor.fieldName] || "";
            break;
        }

        return (
          <>
            {uiDescriptor.childUIDescriptor && uiDescriptor.visible ? (
              <RowCellsFromData
                translationPrefix={props.translationPrefix}
                data={props.data[uiDescriptor.fieldName] || {}}
                uiDescriptorTable={uiDescriptor.childUIDescriptor}
              />
            ) : uiDescriptor.visible ? (
              <TableCell key={uiDescriptor.fieldName}>{renderValue}</TableCell>
            ) : (
              ""
            )}
          </>
        );
      })}
    </>
  );
};

export const GenericTable = (props: {
  table: any[];
  uiDescriptorTable: UiDescriptor[];
  translationPrefix: string;
  updateTable: (table: any[]) => void;
}) => {
  const { t } = useTranslation();
  const [localTable, setLocalTable] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<any[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<any>(null);

  useEffect(() => {
    setLocalTable(props.table);
  }, [props.table]);

  useEffect(() => {
    let copy = [...localTable];
    setRows(copy.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [rowsPerPage, page, localTable]);
  return (
    <TableContainer sx={{ ml: 2, my: 1, maxHeight: "75vh", overflow: "auto" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <IconButton
                onClick={() => {
                  setDialogOpen(true);
                  setDialogData({ id: crypto.randomUUID() });
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </TableCell>
            <HeaderFromUiDescriptor
              translationPrefix={props.translationPrefix}
              uiDescriptorTable={props.uiDescriptorTable}
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((line, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={4}>
                      <Stack direction="column">
                        <IconButton
                          disabled={index === 0}
                          onClick={() => {
                            let copy = [...localTable];
                            copy.splice(index - 1, 0, copy[index]);
                            copy.splice(index + 1, 1);
                            setLocalTable(copy);
                            props.updateTable(copy);
                          }}
                        >
                          <KeyboardArrowUpIcon />
                        </IconButton>
                        <IconButton
                          disabled={index === localTable.length - 1}
                          onClick={() => {
                            let copy = [...localTable];
                            copy.splice(index + 2, 0, copy[index]);
                            copy.splice(index, 1);
                            setLocalTable(copy);
                            props.updateTable(copy);
                          }}
                        >
                          <KeyboardArrowDown />
                        </IconButton>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        onClick={() => {
                          setDialogData(line);
                          setDialogOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>

                    <Grid item xs={4}>
                      <IconButton
                        onClick={() => {
                          let copy = [...localTable];
                          copy.splice(index, 1);
                          setLocalTable(copy);
                          props.updateTable(copy);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
                <RowCellsFromData
                  translationPrefix={props.translationPrefix}
                  data={line}
                  uiDescriptorTable={props.uiDescriptorTable}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[1, 5, 10]}
        count={localTable.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(event, page) => {
          setPage(page);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      {dialogOpen ? (
        <GenericTablePopup
          translationPrefix={props.translationPrefix}
          data={dialogData}
          open={dialogOpen}
          uiDescriptorTable={props.uiDescriptorTable}
          handleOkay={(data) => {
            let index = localTable.findIndex((value) => {
              if (value?.id) {
                return value?.id === data?.id && value?.id;
              } else {
                //UI-Descriptors don't have an id field
                return value?.fieldName === data?.fieldName && value?.fieldName;
              }
            });

            let copy = [...localTable];
            if (index > -1) {
              copy[index] = data;
            } else {
              copy.push(data);
            }
            setLocalTable(copy);
            setDialogOpen(false);
            props.updateTable(copy);
          }}
          handleClose={() => setDialogOpen(false)}
        />
      ) : (
        <></>
      )}
    </TableContainer>
  );
};
