import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Lamp } from "../../model/lamps/lampState";
import { LoraNode } from "../../model/lora-node/LoraNode";

export interface LatLng {
  lat: number;
  lng: number;
}

export interface ViewPort {
  topLeft: LatLng;
  topRight: LatLng;
  bottomLeft: LatLng;
  bottomRight: LatLng;
  center: LatLng;
}

export interface MapState {
  viewPort?: ViewPort;
  selectedElement?: LoraNode | null;
  visibleNodes?: LoraNode[];
  zoomLevel?: number;
}

const initialState: MapState = {};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    updateViewPort: (state, action: PayloadAction<ViewPort>) => {
      state.viewPort = action.payload;
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.set("lat", action.payload.center.lat.toString());
      urlParams.set("lng", action.payload.center.lng.toString());
      window.history.replaceState(null, "", "/map?" + urlParams.toString());
      localStorage.setItem(
        "mapCenterLat",
        action.payload.center.lat.toString()
      );
      localStorage.setItem(
        "mapCenterLng",
        action.payload.center.lng.toString()
      );
    },
    updateSelectedElement: (state, action: PayloadAction<LoraNode | null>) => {
      state.selectedElement = action.payload;
    },

    updateLoraNodesInViewPort: (state, action: PayloadAction<LoraNode[]>) => {
      state.visibleNodes = action.payload;
    },

    updateZoomLevel: (state, action: PayloadAction<number>) => {
      state.zoomLevel = action.payload;
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.set("zoomLevel", action.payload.toString());
      localStorage.setItem("mapZoomLevel", action.payload.toString());
      window.history.replaceState(null, "", "/map?" + urlParams.toString());
    },
  },
});

const mapReducer = mapSlice.reducer;

export const {
  updateViewPort,
  updateSelectedElement,
  updateZoomLevel,
  updateLoraNodesInViewPort,
} = mapSlice.actions;
export default mapReducer;
