import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useTranslation } from "../custom-hooks/useTranslation";
import { useState } from "react";
import { getAuth, updatePassword } from "firebase/auth";
import { fireBaseApp } from "../firebase-config";
import { useSnackbar } from "notistack";
import { Save } from "@mui/icons-material";

export const ChangePasswordDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const User = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const [pwd1, setPWD1] = useState("");
  const [pwd2, setPWD2] = useState("");

  const auth = getAuth(fireBaseApp);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t("ChangePasswordDialog.Title")}</DialogTitle>
      <Stack spacing={2}>
        <TextField
          value={pwd1}
          onChange={(e) => setPWD1(e.currentTarget.value)}
          type={"password"}
        />
        <TextField
          value={pwd2}
          onChange={(e) => setPWD2(e.currentTarget.value)}
          type={"password"}
        />

        <Divider />
        <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
          <IconButton
            color={"primary"}
            disabled={!(pwd1 === pwd2)}
            onClick={() => {
              if (auth.currentUser) {
                updatePassword(auth.currentUser, pwd1)
                  .then(() => {
                    enqueueSnackbar(t("user.password changed"), {
                      variant: "success",
                    });

                    props.onClose();
                  })
                  .catch((e) =>
                    enqueueSnackbar(e.message, { variant: "error" })
                  );
              }
            }}
          >
            <Save />
            {t("Save")}
          </IconButton>
          <IconButton color={"secondary"} onClick={() => props.onClose()}>
            {t("Cancel")}
          </IconButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
