import { blue, green, red, yellow } from "@mui/material/colors";
import { PaletteOptions } from "@mui/material";

const themeColors = {
  primary: {
    main: "#797878",
    dark: "#373737",
    light: "#8E8E8E",
  },
  secondary: {
    main: "#00a693",
    dark: "#007466",
    light: "#33b7a8",
  },
};

const colors = {
  light: {
    primary: themeColors.primary,
    secondary: themeColors.secondary,
    paper: {
      main: "#ffffff",
      light: "#bbbaba",
      dark: "#9d9a9a",
      error: `repeating-linear-gradient(45deg, ${themeColors.primary.light}, ${themeColors.primary.light} 10px, ${red[400]} 10px, ${red[400]} 20px)`,
      default: "#ececec",
      drawer: "#ffffff",
    },
    error: {
      main: red[400],
    },
    warning: {
      main: yellow[900],
    },
    info: {
      main: blue[900],
    },
    success: {
      main: green[800],
    },
    text: {
      secondary: "#ffffff",
      primary: "#000000",
    },
    action: {
      active: themeColors.secondary.light,
      hover: themeColors.secondary.dark,
      hoverOpacity: 0.04,
      selected: themeColors.secondary.main,
      selectedOpacity: 0.008,
      focus: themeColors.secondary.light,
    },
    background: {
      paper: "#ffffff",
      default: "#ececec",
    },
  },
  dark: {
    primary: themeColors.primary,
    secondary: themeColors.secondary,
    paper: {
      main: "#a4a3a3",
      light: "#bbbaba",
      dark: "#616161",
      error: `repeating-linear-gradient(45deg, grey, grey 10px, red 10px, red 20px)`,
      default: "#707070",
      drawer: themeColors.primary.dark,
    },
    error: {
      main: red[400],
    },
    warning: {
      main: yellow[800],
    },
    info: {
      main: blue[900],
    },
    success: {
      main: green[800],
    },
    text: {
      primary: "#ffffff",
      secondary: "#000000",
    },
    action: {
      active: themeColors.secondary.main,
      hover: themeColors.secondary.dark,
      hoverOpacity: 0.04,
      selected: themeColors.secondary.main,
      selectedOpacity: 0.008,
      focus: themeColors.secondary.light,
    },
    background: {
      paper: "#a4a3a3",
      default: themeColors.primary.dark,
    },
  },
};

const paletteLight: PaletteOptions = {
  //light mode is default
  mode: "light",
  primary: colors.light.primary,
  secondary: colors.light.secondary,
  paper: colors.light.paper,
  error: colors.light.error,
  warning: colors.light.warning,
  info: colors.light.info,
  success: colors.light.success,
  action: colors.light.action,
  text: colors.light.text,
  background: colors.light.background,
};
const paletteDark: PaletteOptions = {
  //light mode is default
  mode: "light", // this stays here for a moment. weird behaviour
  primary: colors.dark.primary,
  secondary: colors.dark.secondary,
  paper: colors.dark.paper,
  error: colors.dark.error,
  warning: colors.dark.warning,
  info: colors.dark.info,
  success: colors.dark.success,
  action: colors.dark.action,
  text: colors.dark.text,
  background: colors.dark.background,
};

export { colors, paletteLight, paletteDark };
