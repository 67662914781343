import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useEffect } from "react";
import { fetchAllCachedUIDefinitions } from "../UI-Builder/uiBuilderServices/uiDefinitionState";

export const useGetUIDefinition = (uiDefinitionModel: string) => {
  const uiDefinitions = useSelector((state: RootState) => {
    return state.uiDefinitions.uiDefinitions;
  });
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    //  if (uiDefinitions.length === 0) dispatch(fetchAllCachedUIDefinitions());
  }, [dispatch]);

  return () => {
    const index = uiDefinitions.findIndex((value) => {
      return value.model === uiDefinitionModel;
    });
    return uiDefinitions[index] || null;
  };
};
