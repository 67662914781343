import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useEffect, useState } from "react";
import {
  addMissingTranslation,
  fetchAllTranslations,
  fetchAllTranslationsCache,
} from "../translations/translationsState";
import { UiDefinition } from "../UI-Builder/uiBuilderServices/uiDefinition";
import { UiDescriptor } from "../GenericUIFields/MaintenanceView";
import { IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Save, Translate } from "@mui/icons-material";

const convertUIDescriptorToKeyTable = (
  path: string,
  uiDescriptors: UiDescriptor[]
): string[] => {
  const keys: string[] = [];
  uiDescriptors.forEach((descriptor) => {
    const key = path + "." + descriptor.fieldName;
    if (
      !descriptor.childUIDescriptor ||
      descriptor?.childUIDescriptor?.length === 0
    ) {
      //only push a key of no child is present
      keys.push(key);
    }

    if (descriptor.childUIDescriptor) {
      keys.push(
        ...convertUIDescriptorToKeyTable(key, descriptor.childUIDescriptor)
      );
    }
  });
  return keys;
};
export const useTranslation = () => {
  const translation = useSelector((state: RootState) => state.translations);
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const dispatch: AppDispatch = useDispatch();
  const [hookInitialized, setHookInitialized] = useState(false);
  useEffect(() => {
    if (loggedIn && !translation.initialized && !hookInitialized) {
      console.count("Dispatched Fetch Translation");
      dispatch(fetchAllTranslations());
      setHookInitialized(true);
    } else {
      if (!hookInitialized && !translation.initialized) {
        dispatch(fetchAllTranslationsCache());
        setHookInitialized(true);
      }
    }
  }, [hookInitialized, loggedIn, translation.initialized]);

  return {
    t: (key: string) => {
      try {
        const translatedKey =
          translation.translationsFlat[translation.language][key];
        if (!translatedKey) {
          return (
            <Tooltip
              title={`Add key "${key}" to translation pool`}
              placement={"bottom"}
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    addMissingTranslation({
                      language: translation.language,
                      key: key,
                    })
                  );
                }}
                color={"warning"}
              >
                <Translate />
                <Typography>{key}</Typography>
              </IconButton>
            </Tooltip>
          );
        }
        return translatedKey;
      } catch (e) {
        return key;
      }
    },

    updateTranslationKeysForModel: (uiDefinition: UiDefinition) => {
      const keys = convertUIDescriptorToKeyTable(
        uiDefinition.model,
        uiDefinition.uiDescriptorDefinition
      );

      const translationsInCurrentLanguage =
        translation.translationsFlat[translation.language];

      const drawerKey = "Drawer." + uiDefinition.name;
      const landingPageKey = "landingPage." + uiDefinition.name;
      try {
        const drawerLabel = translationsInCurrentLanguage[drawerKey];
        if (!drawerLabel)
          dispatch(
            addMissingTranslation({
              language: translation.language,
              key: drawerKey,
            })
          );
      } catch (e) {
        dispatch(
          addMissingTranslation({
            language: translation.language,
            key: drawerKey,
          })
        );
      }

      try {
        const landingPageLabel = translationsInCurrentLanguage[landingPageKey];
        if (!landingPageLabel)
          dispatch(
            addMissingTranslation({
              language: translation.language,
              key: landingPageKey,
            })
          );
      } catch (e) {
        dispatch(
          addMissingTranslation({
            language: translation.language,
            key: landingPageKey,
          })
        );
      }
      keys.forEach((key) => {
        try {
          // we check if the key exists
          const translationFound = translationsInCurrentLanguage[key];
          if (!translationFound) {
            dispatch(
              addMissingTranslation({
                key: key,
                language: translation.language,
              })
            );
          }
        } catch (e) {
          dispatch(
            addMissingTranslation({ key: key, language: translation.language })
          );
        }
      });
    },
  };
};
