import {GenericFieldInterface} from "../GenericFieldInterface";
import {
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Select,
    Tooltip,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../store";

import MenuItem from "@mui/material/MenuItem";
import {useCallback, useEffect, useState} from "react";
import {GenericValueHelpResult} from "./GenericValueHelpState";
import {
    useResolveReferenceObjectToSelectValueHelp
} from "../../custom-hooks/useResolveReferenceObjectToSelectValueHelp";
import {OpenInNew} from "@mui/icons-material";
import {GenericConfirmDialog} from "../../GenericUIViews/GenericConfirmDialog";
import {useNavigate} from "react-router-dom";
import {setIsDirty} from "../../GenericUIViews/GenericMaintenanceList/GenericMaintenanceListState";
import {useTranslation} from "../../custom-hooks/useTranslation";
import ClearIcon from '@mui/icons-material/Clear';

export interface GenericSelectFieldInterface
    extends Omit<GenericFieldInterface, "onChange"> {
    onChange: (data: any) => void;
}

export const GenericSelect = (props: GenericSelectFieldInterface) => {
    const valueHelps = useSelector((state: RootState) => state.genericValueHelp);
    const [valueHelp, setValueHelp] = useState<GenericValueHelpResult[]>([]);
    const referenceValueHelp = useResolveReferenceObjectToSelectValueHelp(
        props.uiDescriptor.fieldName,
        props.uiDescriptor.reference
    );
    const dispatch: AppDispatch = useDispatch();

    const {t} = useTranslation();
    const uiIsDirty = useSelector(
        (state: RootState) => state.maintenanceList.isDirty
    );

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClear = () : void => {
        props.onChange(null)
    }

    useEffect(() => {
        const referenceHelp = referenceValueHelp();
        if (referenceHelp.length > 0) setValueHelp(referenceHelp);
        else setValueHelp(valueHelps[props.uiDescriptor.fieldName] || []);
    }, [props.uiDescriptor.reference, props.uiDescriptor.fieldName, valueHelps]);

    return (
        <Grid item xs={props.uiDescriptor.size}>
            <FormControl fullWidth required={props.required}>
                <InputLabel id={props.uiDescriptor.fieldName}>
                    {props.uiDescriptor?.reference ? (
                        <Tooltip title={t("tooltip.navigateToReferencedObject")}>
                            <IconButton
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                <OpenInNew/>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    {t(`${props.translationPrefix}.${props.fieldKey}`)}
                </InputLabel>
                <Select
                    id={props.uiDescriptor.fieldName}
                    value={
                        valueHelp.length > 0 ? props.value?.id || props.value || "" : ""
                    }
                    label={t(props.uiDescriptor.fieldLabel || props.fieldKey)}
                    endAdornment={
                        <InputAdornment position="end" sx={theme => {
                            return {marginRight: theme.spacing(2)}
                        }}>
                            <IconButton onClick={handleClear}>
                                <ClearIcon color={"error"}/>
                            </IconButton>
                        </InputAdornment>
                    }
                >
                    {valueHelp.map((value) => {
                        return (
                            <MenuItem
                                onClick={() => {
                                    props.onChange(
                                        typeof value.object === "object"
                                            ? {...value.object}
                                            : value.object
                                    );
                                }}
                                value={value.id}
                            >
                                {" "}
                                {value.displayValue}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <GenericConfirmDialog
                open={open}
                text={t("confirmDialog.confirmIsDirtyWarning")}
                canAutoConfirm={!uiIsDirty}
                confirmAction={() => {
                    navigate(
                        "../" +
                        props.uiDescriptor.reference?.route?.replace("$", props.value?.id)
                    );
                    dispatch(setIsDirty(false));
                    setOpen(false);
                }}
                cancelAction={() => setOpen(false)}
            />
        </Grid>);
};
