import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { AppDispatch, store } from "../../../store";
import { addMissingTranslation } from "./translationsState";

function getTranslationFromStore(language: string, translationKey: string) {
  const currentState = store.getState();
  let translations = currentState.translations.translations;

  let languageTranslation = translations.find(
    (value) => value.language === language
  );
  if (!languageTranslation) {
    return null;
  }
  let value: any = languageTranslation.translations;
  let keys = translationKey.split(".");
  for (let key of keys) {
    if (!value) return null;
    value = value[key];
  }
  return value;
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "de",
    fallbackLng: "de",
    debug: false,
    saveMissing: true,

    interpolation: {
      escapeValue: false,
    },
  });

//export default i18n;
