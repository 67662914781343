import {
  createLoraNodeFromDiscoveredTTNDevice,
  switchLoraNode,
} from "../../../model/lora-node/loraNodeFirebaseServices";
import CloudIcon from "@mui/icons-material/Cloud";
import { ttnDevice } from "../../../backend/theThingsNetworkAPI";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";
import React from "react";
import { UiEnhancements } from "../../MaterialOnFire/custom-hooks/useGetRoutes";

export const useGetDiscoveredTTNNodesUIEnhancer = () => {
  const enhancer: UiEnhancements = {
    enhanceActionBar: (item: ttnDevice, defaultActionbar) => {
      return [
        {
          action: (state: ttnDevice) => {
            createLoraNodeFromDiscoveredTTNDevice(state);
          },
          name: "takeover",
          icon: <CloudIcon />,
        },
        {
          action: (state: ttnDevice) => {
            switchLoraNode(
              state.ids.application_ids.application_id,
              state.ids.device_id,
              "on"
            );
          },
          name: "On",
          icon: <FlashlightOnIcon />,
        },
        {
          action: (state: ttnDevice) => {
            switchLoraNode(
              state.ids.application_ids.application_id,
              state.ids.device_id,
              "off"
            );
          },
          name: "Off",
          icon: <FlashlightOffIcon />,
        },
      ];
    },
  };

  return { key: "discoveredTTNNodes", enhancer: enhancer };
};
